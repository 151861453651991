import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { TableRow, TableCell, Tooltip, Checkbox, Avatar, Chip, IconButton } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';
import LinkIcon from '@material-ui/icons/Link';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { getFileSize, getFileIcon, localTimeFn, checkPermission } from '../../../utils';
import { displayDateFormatShort, imgPath } from '../../../constants';
import folderIcon from '../../../assets/images/folder_icon.svg';
import sharedIcon from '../../../assets/images/shared_icon.svg';
import collabIcon from '../../../assets/images/img-collab.png';
import { trackActivity } from '../../../utils/mixpanel';
import ocrCompletedIcon from '../../../assets/images/ocr_completed.png'
import ocrInqueueIcon from '../../../assets/images/ocr_inqueue.png'
import ocrProcessingIcon from '../../../assets/images/rolling.gif'
import ocrRejectedIcon from '../../../assets/images/ocr_rejected.png'
import { LinkedIssues } from '../../auth/components'
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CircularProgress from '../../../shared_elements/components/CircularProgress';
const ListLayout = ({ item, conntextMenu, onSelection, selectedItems, fileIcons, onChangeNav, onShareContent, onOpenRefLnk, openDmTags }) => {
  let denominator = (item?.completeness?.total_count) > 0 ?(item?.completeness?.total_count) :1
  return (
    <TableRow hover tabIndex={-1}>
      { (checkPermission('records','data_room','DN') || checkPermission('records','data_room','MOV') || checkPermission('records','data_room','D')) ?
        <TableCell className="file-info">
          <Checkbox
            name={`${item.uuid}_${item.type}`}
            id={`${item.uuid}_${item.type}`}
            color="primary"
            className="checkbox-icon"
            size="small"
            checked={selectedItems.ids.find(listItem => (listItem.uuid == item.uuid && listItem.type === item.type)) ? true : false}
            onChange={(e) => onSelection(e.target.checked)}
          />
        </TableCell>:null
      }
      
      <TableCell className="file-info" width="380">
        <div className="flex-centered">
          {item.type == 'file' ? null :
            <Tooltip placement='top' title={
              <div>
                <div>Total Folders: {item?.completeness?.total_count}</div>
                <div> Folders with File(s): {item?.completeness?.complete_count}</div>
              </div>
            } arrow>
              <div className="completnes-percentage" style={{ marginRight: "4px" }}>
                <CircularProgress value={item?.completeness?.complete_count / denominator} />
              </div>
            </Tooltip>
          }
          <ul className="list-inline" style={{ minWidth: '30px' }}>
            <li className="list-inline-item">
              <img style={{ width: item.type === 'folder' ? '26px' : '20px' }} className="folder-img" src={item.type === 'folder' ? folderIcon : getFileIcon(fileIcons, item.extension)} alt={item.extension} />
            </li>
          </ul>
          <div>
            <h5 className="flex-centered"  style={{ cursor: 'pointer' }}>
              <span onClick={(e) => { item.type === 'folder' ? onChangeNav() : window.open(`/records/preview/${item.uuid}`, '_blank'); item.type !== 'folder' ? trackActivity('File Previewed', { page_title: 'Dataroom', event_type: 'Preview(List Layout)', extension: item.extension ? item.extension : '', size: item.size ? item.size : '', file_name: item.name, uuid: item.uuid }) : e.preventDefault() }}> {item.name}</span>
              {checkPermission('records', 'data_room', 'SHR') ?
                <span>
                  {item.share_enabled ?
                    <span className="shared-info">
                      <Tooltip title="Copy Shared Link" arrow>
                        <img style={{ marginLeft: '6px' }} width="20" onClick={onShareContent} src={sharedIcon} alt="" />
                      </Tooltip>
                    </span> : null
                  }
                </span> : null
              }
                <span>
                  { item.is_folder_collaborated && checkPermission('records', 'data_room', 'SHR') ?
                    <span className="shared-info">
                      <img style={{ marginLeft: '6px', height:'18px', width:'18px' }} onClick={onShareContent} src={collabIcon} alt="" />
                    </span> : null
                  }
                </span>
            </h5>
            <div className='more-info-list'>
              <ul className='list-inline'>
                {item.type === 'file' ? 
                  <li className='list-inline-item'>
                    <Chip size="small" className="file-status" label={item.status === 'Published' ? 'Published' : 'Unpublished'} />
                  </li>: null
                }
                {checkPermission('records','ref_link','R') && item.external_links_count ?
                  <li className='list-inline-item' onClick={onOpenRefLnk}>
                    <Tooltip title='Reference Link'>
                      <span style={{cursor:'pointer'}} className='flex-centered'><LinkIcon color='primary' fontSize='small' /><span>({item.external_links_count})</span></span>
                    </Tooltip>
                    
                  </li>:null
                }
                {checkPermission('records','records_issue','R') && item.type === 'file' && item?.issues_count ?
                  <li className='list-inline-item'>
                      <LinkedIssues count={item?.issues_count||0} recordType={item.type} recordUuid={item.uuid} locType='dataroom' getResponseBack={() => console.log('')} />
                  </li>:null
                }
                { checkPermission('records','tags','R') ?
                  <li className='list-inline-item' onClick={openDmTags} style={{cursor:'pointer'}}>
                    {(() => {
                      let toolTipTitle = item?.tags?.length ? `Tags: ${item.tags.map((tag) => tag.name).join(',')}` :'Click to Add Tags'
                      return(
                        <Tooltip title={toolTipTitle} arrow>
                          <span className='flex-centered'><LocalOfferIcon fontSize='small' color='primary' /><span>({item?.tags?.length||0})</span></span>
                        </Tooltip>
                      )
                    })()}
                  </li>:null
                }
              </ul>    
            </div>
          </div>
        </div>
      </TableCell>
      <TableCell style={{display:'none'}} > {item.type === 'folder' ? 'N/A':  item.page_count ? item.page_count : 'N/A'}</TableCell>
      <TableCell style={{width:'90px'}} > {item.type === 'folder' ? 'Files: ' + item?.count_details?.file_count ? item?.count_details?.file_count : '0' : getFileSize(item.size)}</TableCell>
      <TableCell>
        <div className="flex-centered" style={{ minWidth: '85px' }}>
          {item.modified ? localTimeFn(item.modified) : '--'}
        </div>
      </TableCell>

      <TableCell >
        {item.ocr_status ?
          item.ocr_status === "3" && ["3", "4"].includes(item.solr_status) ? <Tooltip title="Completed" arrow><img width="20" src={ocrCompletedIcon} alt="Completed" /></Tooltip> :
            ['4', '5'].includes(item.ocr_status) || item.solr_status == '5' ? <Tooltip title="Rejected" arrow><img width="20" src={ocrRejectedIcon} alt="Rejected" /></Tooltip> :
              <Tooltip title="Processing" arrow><img width="20" src={ocrInqueueIcon} alt="Processing" /></Tooltip>
          : <span>N/A</span>
        }
      </TableCell>
      <TableCell >
        <span className='flex-centered'>
          <span>
          {conntextMenu}
          </span>
          <span>
            {item.type === 'file'?
            <IconButton size='small' style={{marginBottom:'4px'}}>
              <Tooltip title='Preview file in same window'><OpenInNewIcon style={{fontSize:'13px'}} color='primary' onClick={(e) => { item.type === 'folder' ? onChangeNav() : window.location.href = `/records/preview/${item.uuid}`; item.type !== 'folder' ? trackActivity('File Previewed', { page_title: 'Dataroom', event_type: 'Preview(List Layout)', extension: item.extension ? item.extension : '', size: item.size ? item.size : '', file_name: item.name, uuid: item.uuid }) : e.preventDefault() }} fontSize='small'/></Tooltip>
            </IconButton>:null}
          </span>
        </span>
      </TableCell>
    </TableRow>
  )
}
export default withRouter(ListLayout);
